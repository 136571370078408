import { createReducer, on } from '@ngrx/store';

import { IBuyBox, IPageOfBuyBoxes } from '@shared/models/buy-boxes';

import {
  resetBuyBoxesAction,
  setBuyBoxesLoadingAction,
  listOwnBuyBoxesAction,
  listOwnBuyBoxesActionSuccess,
  listOwnBuyBoxesActionFailure,
  fetchBuyBoxAction,
  fetchBuyBoxActionSuccess,
  fetchBuyBoxActionFailure,
  createBuyBoxAction,
  createBuyBoxActionSuccess,
  createBuyBoxActionFailure,
  editBuyBoxAction,
  editBuyBoxActionSuccess,
  editBuyBoxActionFailure,
  deleteBuyBoxAction,
  deleteBuyBoxActionSuccess,
  deleteBuyBoxActionFailure,
} from './buy-boxes.actions';

export interface BuyBoxesState {
  loading: boolean;
  errors: boolean;
  pageOfBuyBoxes?: IPageOfBuyBoxes;
  item?: {
    loading: boolean;
    buyBox?: IBuyBox;
  };
  deleting?: string;
}

export const initialState: BuyBoxesState = {
  loading: false,
  errors: false,
  pageOfBuyBoxes: undefined,
  item: undefined,
};

export const buyBoxReducer = createReducer(
  initialState,
  on(resetBuyBoxesAction, () => ({ ...initialState })),

  on(setBuyBoxesLoadingAction, (state, action) => ({
    ...state,
    loading: action.loading,
  })),
  on(listOwnBuyBoxesAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
  })),
  on(listOwnBuyBoxesActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfBuyBoxes: { ...action },
  })),
  on(listOwnBuyBoxesActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
  })),
  on(fetchBuyBoxAction, (state) => ({
    ...state,
    item: {
      loading: true,
      buyBox: undefined,
    },
  })),
  on(fetchBuyBoxActionSuccess, (state, action) => ({
    ...state,
    item: {
      loading: false,
      buyBox: { ...action },
    },
  })),
  on(fetchBuyBoxActionFailure, (state) => ({
    ...state,
    item: {
      loading: false,
      buyBox: undefined,
    },
  })),
  on(createBuyBoxAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
  })),
  on(createBuyBoxActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfBuyBoxes: state.pageOfBuyBoxes
      ? {
          ...state.pageOfBuyBoxes,
          count: state.pageOfBuyBoxes.count + 1,
          results: [{ ...action }, ...state.pageOfBuyBoxes.results],
        }
      : {
          count: 1,
          results: [{ ...action }],
        },
  })),
  on(createBuyBoxActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
  })),
  on(editBuyBoxAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
  })),
  on(editBuyBoxActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfBuyBoxes: state.pageOfBuyBoxes
      ? {
          ...state.pageOfBuyBoxes,
          results: state.pageOfBuyBoxes.results.map((buyBox) =>
            buyBox.id === action.id ? { ...action } : buyBox
          ),
        }
      : {
          count: 1,
          results: [{ ...action }],
        },
  })),
  on(editBuyBoxActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
  })),
  on(deleteBuyBoxAction, (state, action) => ({
    ...state,
    deleting: action.id,
    errors: false,
  })),
  on(deleteBuyBoxActionSuccess, (state, action) => ({
    ...state,
    deleting: undefined,
    errors: false,
    pageOfBuyBoxes: state.pageOfBuyBoxes
      ? {
          ...state.pageOfBuyBoxes,
          count: state.pageOfBuyBoxes.count - 1,
          results: state.pageOfBuyBoxes.results.filter(
            (buyBox) => buyBox.id !== action.id
          ),
        }
      : {
          count: 0,
          results: [],
        },
  })),
  on(deleteBuyBoxActionFailure, (state) => ({
    ...state,
    deleting: undefined,
  }))
);
