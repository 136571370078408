import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { profileLinks } from '@core/constants';
import { ILink, IProfileLink } from '@shared/models/links';

import { Store } from '@ngrx/store';
import { IUser } from '@shared/models/user';
import { AppState } from '@state/app.state';
import { selectAuthUser } from '@state/auth/auth.selectors';
import { setAuthModal } from '@state/auth/auth.actions';

@UntilDestroy()
@Component({
  selector: 'app-topbar-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  protected user?: IUser;
  protected homeScreen = false;

  protected featureFlags: { [key: string]: boolean } = {
    fe_favorite_property: false,
    fe_ui_docs: false,
    fe_buy_boxes: false,
  };

  protected links: IProfileLink[] = profileLinks;
  protected currentPath?: string;
  protected linksWithActiveStatus: (IProfileLink & { isActive: boolean })[] =
    [];

  constructor(
    private store: Store<AppState>,
    private featureFlagsService: FeatureFlagsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (
        ((this.router.url === '/' ||
          this.router.url.startsWith('/page/') ||
          this.router.url.startsWith('/?')) &&
          !this.router.url.startsWith('/maintenance')) ||
        this.router.url.startsWith('/auth/password-reset')
      ) {
        this.homeScreen = true;
      } else {
        this.homeScreen = false;
      }
    });

    this.currentPath = this.router.url;
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      this.currentPath = this.router.url;
      this.updateLinksActiveState();
    });

    this.updateLinksActiveState();

    this.featureFlagsService
      .isEnabled('fe_favorite_property')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_favorite_property'] = !!enabled;
      });
    this.featureFlagsService
      .isEnabled('fe_ui_docs')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_ui_docs'] = !!enabled;
      });
    this.featureFlagsService
      .isEnabled('fe_buy_boxes')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_buy_boxes'] = !!enabled;
      });
  }

  showSignInModal() {
    this.store.dispatch(setAuthModal({ authModal: 'signup' }));
  }

  trackByFn(index: number, link: ILink): string {
    return link.title;
  }

  private updateLinksActiveState(): void {
    this.linksWithActiveStatus = this.links.map((item) => ({
      ...item,
      isActive: !!item.children?.some(
        (child) => child.path === this.currentPath
      ),
    }));
  }
}
