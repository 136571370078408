<ng-container *ngIf="wrapper; else defaultContent">
  <ng-container
    *ngTemplateOutlet="
      wrapper;
      context: {
        $implicit: text,
        loading: loading,
        handleClick: handleClick.bind(this)
      }
    "
  ></ng-container>
</ng-container>

<ng-template #defaultContent>
  <button (click)="handleClick()" [disabled]="loading">
    {{ text }}
  </button>
</ng-template>
