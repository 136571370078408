<form [formGroup]="signUpForm" (ngSubmit)="submitHandler()">
  <div class="grid gap-x-4 md:grid-cols-2">
    <app-shared-input
      label="First name"
      [for]="'firstName'"
      [value]="signUpForm.value.firstName"
      [focused]="focused === 'firstName'"
      [touched]="signUpForm.get('firstName')?.touched"
      [errors]="signUpForm.get('firstName')?.errors"
      [disabled]="loading"
    >
      <input
        formControlName="firstName"
        name="firstName"
        id="firstName"
        (focus)="focusHandler($event)"
        (blur)="blurHandler($event)"
      />
    </app-shared-input>
    <app-shared-input
      label="Last name"
      [for]="'surname'"
      [value]="signUpForm.value.surname"
      [focused]="focused === 'surname'"
      [touched]="signUpForm.get('surname')?.touched"
      [errors]="signUpForm.get('surname')?.errors"
      [disabled]="loading"
    >
      <input
        formControlName="surname"
        name="surname"
        id="surname"
        (focus)="focusHandler($event)"
        (blur)="blurHandler($event)"
      />
    </app-shared-input>
  </div>

  <app-shared-input
    label="Email"
    [for]="'email'"
    [value]="signUpForm.value.email"
    [focused]="focused === 'email'"
    [touched]="signUpForm.get('email')?.touched"
    [errors]="signUpForm.get('email')?.errors"
    [disabled]="loading"
  >
    <input
      formControlName="email"
      id="email"
      name="email"
      type="email"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>

  <app-shared-input
    label="Phone number"
    [for]="'phoneNumber'"
    [value]="signUpForm.value.phoneNumber"
    [focused]="focused === 'phoneNumber'"
    [touched]="signUpForm.get('phoneNumber')?.touched"
    [errors]="signUpForm.get('phoneNumber')?.errors"
    [disabled]="loading"
    prefix="+1"
  >
    <input
      formControlName="phoneNumber"
      name="phoneNumber"
      id="phoneNumber"
      type="tel"
      (paste)="pastePhoneNumberHandler($event)"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
      mask="(000) 000-0000"
    />
  </app-shared-input>
  <p class="text-sm text-gray-500 dark:text-gray-400 ml-1 -mt-2 mb-4">
    We will send an SMS verification code to your phone number
  </p>

  <app-shared-input
    label="Password"
    [for]="'password'"
    [value]="signUpForm.value.password"
    [focused]="focused === 'password'"
    [touched]="signUpForm.get('password')?.touched"
    [errors]="signUpForm.get('password')?.errors"
    [disabled]="loading"
    type="password"
    (toggleVisibility)="passwordVisibility = $event"
  >
    <input
      formControlName="password"
      name="password"
      id="password"
      [type]="passwordVisibility"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>

  <app-shared-input
    label="Password confirmation"
    [for]="'passwordConfirmation'"
    [value]="signUpForm.value.passwordConfirmation"
    [focused]="focused === 'passwordConfirmation'"
    [touched]="signUpForm.get('passwordConfirmation')?.touched"
    [errors]="signUpForm.get('passwordConfirmation')?.errors"
    [disabled]="loading"
    type="password"
    (toggleVisibility)="passwordConVisibility = $event"
  >
    <input
      formControlName="passwordConfirmation"
      name="passwordConfirmation"
      id="passwordConfirmation"
      [type]="passwordConVisibility"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>

  <app-shared-input
    [label]="
      !loadingInviteSources
        ? 'How did you hear about us?'
        : 'Loading invite sources...'
    "
    [selector]="true"
    for="inviteSource"
    [value]="signUpForm.value.inviteSource"
    [focused]="focused === 'inviteSource'"
    [touched]="signUpForm.get('inviteSource')?.touched"
    [errors]="signUpForm.get('inviteSource')?.errors"
    [disabled]="loading || loadingInviteSources"
  >
    <mat-select
      formControlName="inviteSource"
      [ngClass]="{
        'select-placeholder': !signUpForm.value.inviteSource
      }"
    >
      <ng-container *ngIf="!loadingInviteSources">
        <mat-option
          *ngFor="let source of inviteSources"
          [value]="source.id?.toUpperCase()"
        >
          {{ source.name }}
        </mat-option>
      </ng-container>
    </mat-select>
  </app-shared-input>

  <div class="terms-of-service">
    <mat-checkbox
      class="custom-checkbox"
      color="primary"
      formControlName="terms"
    >
      I agree to Rebuilt’s
      <a href="https://www.rebuilt.com/terms-of-use" target="_blank"
        >Terms of Use</a
      >
      and
      <a href="https://www.rebuilt.com/privacy-policy" target="_blank"
        >Privacy Policy.</a
      >
    </mat-checkbox>
    <div
      *ngIf="
        this.signUpForm.controls['terms'].touched &&
        signUpForm.controls['terms'].hasError('required')
      "
      class="input-error-message"
    >
      You must agree to the terms of service to continue.
    </div>
  </div>

  <div class="marketing-emails">
    <mat-checkbox
      class="marketing-checkbox"
      color="primary"
      formControlName="allowMarketingEmails"
    >
      I authorize Rebuilt to email or text me for informational or marketing
      purposes.
    </mat-checkbox>
  </div>

  <app-shared-button [loading]="loading"> Sign up </app-shared-button>
</form>
