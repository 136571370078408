import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

import { menuLinks } from '@core/constants';
import { ILink } from '@shared/models/links';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthModal } from '@state/auth/auth.selectors';
import { selectConfigOpenModal } from '@state/config/config.selectors';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shared-layout-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Input() guest = false;

  protected openModal?: string;
  protected openAuthModal?: string;
  protected homeScreen = false;
  protected open = false;
  protected links: ILink[] = menuLinks;
  protected isMobile = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.store
      .select(selectConfigOpenModal)
      .pipe(untilDestroyed(this))
      .subscribe((openModal) => {
        this.openModal = openModal;
        this.changeDetectorRef.detectChanges();
      });

    this.store
      .select(selectAuthModal)
      .pipe(untilDestroyed(this))
      .subscribe((openAuthModal) => {
        this.openAuthModal = openAuthModal;
        this.changeDetectorRef.detectChanges();
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (
        (this.router.url === '/' ||
          this.router.url.startsWith('/page/') ||
          this.router.url.startsWith('/?')) &&
        !this.router.url.startsWith('/maintenance') &&
        !this.router.url.startsWith('/disclosures')
      ) {
        this.homeScreen = true;
      } else {
        this.homeScreen = false;
      }
    });
  }

  trackByFn(index: number, link: ILink): string {
    return link.title;
  }

  preventDefault(e: MouseEvent, link: ILink): void {
    if (link.children) {
      e.preventDefault();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.open = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (
      event.target instanceof HTMLElement &&
      event.target.closest('.links') &&
      !event.target.closest('.container')
    ) {
      this.open = false;
    }
  }
}
