<div class="wrapper" [ngClass]="{ open }">
  <div class="container">
    <header>
      <div class="overlay">
        <ng-container *ngIf="user">
          <div class="sign-out">
            <a routerLink="/logout" (click)="closeMenuHandler()">
              Log out
              <app-shared-icon
                name="log-out-white"
                class="icon"
                [size]="15"
              ></app-shared-icon>
            </a>
          </div>
          <a routerLink="/profile/account" (click)="closeMenuHandler()">
            <img src="/assets/images/user.png" alt="user icon" />
          </a>
          <a
            *ngIf="user"
            routerLink="/profile/account"
            (click)="closeMenuHandler()"
          >
            <div class="name">{{ user.firstName }} {{ user.surname }}</div>
            <div class="email">{{ user.email }}</div>
          </a>
        </ng-container>
        <div class="sign-in" *ngIf="!user">
          <div class="link" (click)="showSignInModal()">
            <app-shared-icon
              name="user-white"
              class="icon"
              [size]="20"
            ></app-shared-icon>
            Sign in
          </div>
        </div>
      </div>
    </header>

    <ul>
      <li *ngIf="!user">
        <a routerLink="/" (click)="closeMenuHandler()">Marketplace</a>
      </li>
      <ng-container *ngIf="user">
        <ng-container
          *ngFor="let item of linksWithActiveStatus; trackBy: trackByFn"
        >
          <li
            *ngIf="!item.path"
            class="label"
            [ngClass]="{ active: item.isActive }"
          >
            <ng-icon
              *ngIf="item.icon"
              [name]="item.icon"
              class="icon"
              size="18"
            ></ng-icon>
            <span>{{ item.title }}</span>
          </li>
          <ng-container *ngFor="let child of item.children">
            <li>
              <a
                *ngIf="
                  !(
                    child.featureFlag &&
                    featureFlags[child.featureFlag] !== true
                  )
                "
                [routerLink]="child.path"
                [ngClass]="{ active: child.path === currentPath }"
                (click)="closeMenuHandler()"
              >
                {{ child.title }}
              </a>
            </li>
            <ng-container *ngIf="item.title === 'Sell'">
              <li>
                <app-submit-a-deal-button
                  [wrapper]="submitDealWrapper"
                ></app-submit-a-deal-button>
                <ng-template
                  #submitDealWrapper
                  let-text
                  let-loading="loading"
                  let-handleClick="handleClick"
                >
                  <a
                    (click)="handleClick()"
                    [ngClass]="{
                      'cursor-pointer': !loading,
                      'cursor-not-allowed': loading
                    }"
                  >
                    {{ text }}
                  </a>
                </ng-template>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- <li>
          <a routerLink="/profile/offers" (click)="closeMenuHandler()">
            My Offers</a
          >
        </li>
        <li *ngIf="favoritesFeatureFlag$ | async">
          <a routerLink="/profile/favorites" (click)="closeMenuHandler()">
            My Favorites
          </a>
        </li>
        <li>
          <a routerLink="/my-deals" (click)="closeMenuHandler()"> My Deals </a>
        </li>
        <li *ngIf="buyBoxesFeatureFlag$ | async">
          <a routerLink="/buy-boxes" (click)="closeMenuHandler()">
            My Buy Boxes
          </a>
        </li>
        <li>
          <a routerLink="/profile/account" (click)="closeMenuHandler()">
            Account Profile
          </a>
        </li> -->
        <hr />
      </ng-container>
      <app-submit-a-deal-button
        [wrapper]="submitDealWrapper"
      ></app-submit-a-deal-button>
      <ng-template
        #submitDealWrapper
        let-text
        let-loading="loading"
        let-handleClick="handleClick"
      >
        <li>
          <a
            class="submit-deal"
            (click)="handleClick()"
            [ngClass]="{
              'cursor-pointer': !loading,
              'cursor-not-allowed': loading
            }"
            >{{ text }}</a
          >
        </li>
      </ng-template>
      <li *ngFor="let item of links; let i = index; trackBy: trackByFn">
        <a
          [href]="item.path"
          target="_blank"
          (click)="toggleSubmenu($event, i)"
        >
          {{ item.title }}
          <app-shared-icon
            *ngIf="item.children"
            [name]="showSubmenu === i ? 'caret-right' : 'chevron-down'"
            [size]="10"
            class="icon"
          ></app-shared-icon
        ></a>
        <ul *ngIf="item.children" [ngClass]="{ open: showSubmenu === i }">
          <li *ngFor="let subItem of item.children; trackBy: trackByFn">
            <a [href]="subItem.path" target="_blank">
              {{ subItem.title }}
            </a>
          </li>
        </ul>
      </li>
      <!-- <li class="support">
        For support: Email us at
        <a href="mailto:help@rebuilt.com" class="support">{{
          'help@rebuilt.com'
        }}</a>
      </li> -->
    </ul>

    <div class="text-sm text-right pb-4 pr-4 pl-2 opacity-70">
      For support:
      <a href="mailto:help@rebuilt.com">{{ 'help@rebuilt.com' }}</a>
    </div>

    <footer>
      &copy; {{ year }}
      <a href="https://rebuilt.com" target="_blank">Rebuilt</a> - All rights
      reserved.
    </footer>
  </div>
</div>
