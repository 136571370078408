<div *ngIf="user" class="wrapper" [matMenuTriggerFor]="userMenu">
  <div class="name">
    Hi, {{ user.firstName }}
    <app-shared-icon
      name="caret-down"
      [size]="10"
      class="icon"
    ></app-shared-icon>
  </div>
  <img src="/assets/images/user.png" width="35" alt="user" />
</div>

<mat-menu #userMenu="matMenu">
  <div class="support-wrapper">
    For support: Email us at<br />
    <a href="mailto:help@rebuilt.com" class="support">{{
      'help@rebuilt.com'
    }}</a>
  </div>
  <div class="content">
    <ng-container
      *ngFor="let item of linksWithActiveStatus; trackBy: trackByFn"
    >
      <div
        *ngIf="!item.path"
        class="label"
        [ngClass]="{ active: item.isActive }"
      >
        <ng-icon
          *ngIf="item.icon"
          [name]="item.icon"
          class="icon"
          size="18"
        ></ng-icon>
        <span>{{ item.title }}</span>
      </div>
      <ng-container *ngFor="let child of item.children">
        <a
          *ngIf="
            !(child.featureFlag && featureFlags[child.featureFlag] !== true)
          "
          [routerLink]="child.path"
          [ngClass]="{ active: child.path === currentPath }"
        >
          {{ child.title }}
        </a>
      </ng-container>
      <ng-container *ngIf="item.title === 'Sell'">
        <app-submit-a-deal-button
          [wrapper]="submitDealWrapper"
        ></app-submit-a-deal-button>
        <ng-template
          #submitDealWrapper
          let-text
          let-loading="loading"
          let-handleClick="handleClick"
        >
          <a
            (click)="handleClick()"
            [ngClass]="{
              'cursor-pointer': !loading,
              'cursor-not-allowed': loading
            }"
          >
            {{ text }}
          </a>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</mat-menu>

<div *ngIf="!user && homeScreen" class="text-sm">
  <span class="hidden 2xl:inline">For support: Email us at </span>
  <a href="mailto:help@rebuilt.com">{{ 'help@rebuilt.com' }}</a>
</div>

<app-shared-button
  *ngIf="!user && !homeScreen"
  size="small"
  (click)="showSignInModal()"
>
  Join Rebuilt
</app-shared-button>
