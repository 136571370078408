<div
  class="wrapper"
  [ngClass]="{
    'is-desktop': !isMobile,
    guest,
    z2: !!openModal || (!!openAuthModal && !homeScreen)
  }"
>
  <div class="logos">
    <a routerLink="/" class="logo">
      <img src="/assets/images/logo.png" alt="logo" />
    </a>

    <a routerLink="/" class="logo twnsqr">
      <img src="assets/images/TwnSqr_horizontal_color.svg" alt="twnsqr_logo" />
    </a>
  </div>

  <div class="links">
    <app-menu-mobile
      class="mobile-menu"
      [open]="open"
      [guest]="guest"
      [links]="links"
      (closeMenu)="open = false"
    ></app-menu-mobile>
    <ul>
      <app-submit-a-deal-button
        [wrapper]="submitDealWrapper"
      ></app-submit-a-deal-button>
      <ng-template
        #submitDealWrapper
        let-text
        let-loading="loading"
        let-handleClick="handleClick"
      >
        <li
          class="custom-btn"
          (click)="handleClick()"
          [ngClass]="{ disabled: loading }"
        >
          {{ text }}
        </li>
      </ng-template>
      <li
        *ngFor="let item of links; let i = index; trackBy: trackByFn"
        [matMenuTriggerFor]="item.children ? menu : null"
      >
        <a
          [href]="item.path"
          target="_blank"
          (click)="preventDefault($event, item)"
        >
          {{ item.title }}
          <app-shared-icon
            *ngIf="item.children"
            [name]="guest ? 'chevron-down-white' : 'chevron-down'"
            [size]="10"
            class="icon"
          ></app-shared-icon>
          <app-shared-icon
            *ngIf="item.children && !guest"
            name="chevron-down-blue"
            [size]="10"
            class="icon-active"
          ></app-shared-icon>
        </a>
        <mat-menu #menu="matMenu">
          <a
            *ngFor="let child of item.children"
            [href]="child.path"
            target="_blank"
          >
            {{ child.title }}</a
          >
        </mat-menu>
      </li>
    </ul>
  </div>

  <app-topbar-user class="user-dropdown"></app-topbar-user>

  <div class="mobile-icon" (click)="open = !open">
    <app-shared-icon [name]="guest ? 'menu-white' : 'menu'"></app-shared-icon>
  </div>
</div>
