import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { REQUEST_MAGIC_TOKEN } from '@graphql/queries/magic-token.queries';
import { IRequestMagicTokenData } from '@shared/models/magic-token';

@Injectable({
  providedIn: 'root',
})
export class MagicTokenService {
  constructor(private apollo: Apollo) {}

  requestMagicToken(): Observable<IRequestMagicTokenData> {
    return this.apollo
      .query({
        query: REQUEST_MAGIC_TOKEN,
      })
      .pipe(map((result) => result.data));
  }
}
