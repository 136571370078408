import { createAction, props } from '@ngrx/store';

import {
  IExternalPropertyUserListing,
  IMyDealsMetricsData,
} from '@shared/models/my-deals';

export const resetMyDealsAction = createAction('[MyDeals] Reset');

export const listOwnExternalPropertiesAction = createAction(
  '[MyDeals] Fetch List Own External Properties'
);

export const listOwnExternalPropertiesActionSuccess = createAction(
  '[MyDeals] Fetch List Own External Properties Success',
  props<{ properties: IExternalPropertyUserListing[] }>()
);

export const listOwnExternalPropertiesActionFailure = createAction(
  '[MyDeals] Fetch List Own External Properties Failure'
);

export const myDealsMetricsAction = createAction(
  '[MyDeals] Fetch My Deals Metrics'
);

export const myDealsMetricsActionSuccess = createAction(
  '[MyDeals] Fetch My Deals Metrics Success',
  props<{ metrics: IMyDealsMetricsData['self'] }>()
);

export const myDealsMetricsActionFailure = createAction(
  '[MyDeals] Fetch My Deals Metrics Failure'
);
