import { createReducer, on } from '@ngrx/store';

import { StringPipe } from '@shared/pipes';
import { IPageOfFavorites } from '@shared/models/favorites';

import {
  resetFavoritesAction,
  fetchFavoritesAction,
  fetchFavoritesActionSuccess,
  fetchFavoritesActionFailure,
  setPropertyAsFavoriteAction,
  setPropertyAsFavoriteActionSuccess,
  setPropertyAsFavoriteActionFinish,
  removePropertyFromFavoritesAction,
  removePropertyFromFavoritesActionSuccess,
  removePropertyFromFavoritesActionFinish,
} from './favorites.actions';

export interface FavoritesState {
  loading: boolean;
  errors: boolean;
  pageOfFavorites?: IPageOfFavorites;
}

export const initialState: FavoritesState = {
  loading: false,
  errors: false,
  pageOfFavorites: undefined,
};

export const favoritesReducer = createReducer(
  initialState,
  on(resetFavoritesAction, () => ({ ...initialState })),

  on(fetchFavoritesAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
  })),
  on(fetchFavoritesActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfFavorites: {
      ...action,
      results: [
        ...action.results.map((property) => ({
          ...property,
          price: new StringPipe().transform(
            property.price,
            'strPriceToDecimal'
          ),
        })),
      ],
    },
  })),
  on(fetchFavoritesActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
  })),
  on(setPropertyAsFavoriteAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(setPropertyAsFavoriteActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    pageOfFavorites: state.pageOfFavorites
      ? {
          ...state.pageOfFavorites,
          count: state.pageOfFavorites.count + 1,
          results: [action.property, ...state.pageOfFavorites?.results],
        }
      : {
          count: 1,
          results: [{ ...action.property }],
        },
  })),
  on(setPropertyAsFavoriteActionFinish, (state) => ({
    ...state,
    loading: false,
  })),
  on(removePropertyFromFavoritesAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(removePropertyFromFavoritesActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    pageOfFavorites: state.pageOfFavorites
      ? {
          ...state.pageOfFavorites,
          count: state.pageOfFavorites.count - 1,
          results: state.pageOfFavorites?.results.filter(
            (property) => property.id !== action.property.id
          ),
        }
      : undefined,
  })),
  on(removePropertyFromFavoritesActionFinish, (state) => ({
    ...state,
    loading: false,
  }))
);
