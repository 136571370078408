<div class="wrapper">
  <div class="container">
    <div class="logo">
      <img src="assets/images/logo-white.png" alt="logo" />
    </div>

    <nav>
      <ul>
        <li>
          <app-submit-a-deal-button
            [wrapper]="submitDealWrapper"
          ></app-submit-a-deal-button>
          <ng-template
            #submitDealWrapper
            let-text
            let-loading="loading"
            let-handleClick="handleClick"
          >
            <a
              (click)="handleClick()"
              [ngClass]="{
                'cursor-pointer': !loading,
                'cursor-not-allowed': loading
              }"
            >
              {{ text }}
            </a>
          </ng-template>
          <a
            *ngFor="let item of menuLinks; trackBy: trackByFn"
            [href]="item.path"
            target="_blank"
            >{{ item.title }}</a
          >
        </li>
      </ul>
    </nav>

    <div class="info">
      <p *ngIf="isAuthenticated | async" class="mb-7">
        While Rebuilt makes every effort to vet the investment opportunities we
        display on our website, we can’t guarantee the condition of the
        property, the truth or accuracy of any third-party valuations, reports,
        or statements, or that any particular property represents a sound or
        advisable investment opportunity. All real estate investments are
        susceptible to substantial risks, including local, state and national
        market conditions, environmental hazards and disasters, undisclosed or
        unknown property conditions, zoning or land use changes, the
        availability of credit, and other risks of loss. Real Estate investors
        who purchase the right to acquire real estate from REBUILT assume the
        entire risk of their investment and are advised to independently
        investigate and verify the truth, completeness, and accuracy of all
        information regarding the property.
      </p>
      <p class="mb-2">
        Rebuilt is a licensed broker. Texas Real Estate Commission:
        <a href="/assets/IABS-1-0.pdf" target="_blank"
          >Information About Brokerage Services</a
        >,
        <a
          href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-4-1_1.pdf"
          target="_blank"
          >Consumer Protection Notice</a
        >
      </p>
      <p class="mb-2">
        TX DRE #9013183, GA DRE #80400, TN DRE #265769, PA DRE #RBR005541, AL
        DRE #000153549, DE DRE #RB0031208, MS DRE #23939, Kentucky DRE #284300,
        Ohio DRE #REC.2022005839
      </p>
      <p class="mb-2">
        Pennsylvania
        <a href="/assets/Pennsylvania-RELRA-Consumer-Notice.pdf" target="_blank"
          >RELRA Consumer Protection Notice</a
        >
      </p>
      <p class="mb-7">Phone: 615.617.5512</p>
      <p class="terms-and-policy">
        <a href="https://www.rebuilt.com/terms-of-use" target="_blank"
          >Terms of Use</a
        >
        <a href="https://www.rebuilt.com/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
        <a href="https://www.rebuilt.com/terms-of-use" target="_blank"
          >Terms of Service</a
        >
      </p>
    </div>

    <div class="social">
      <div class="icons">
        <a
          *ngFor="let item of socialLinks; trackBy: trackByFn"
          [href]="item.path"
          target="_blank"
        >
          <app-shared-icon [name]="item.icon" [size]="30"></app-shared-icon>
        </a>
      </div>
      <div class="right">
        <app-shared-icon
          name="housing-opportunity"
          [size]="60"
        ></app-shared-icon>
      </div>
    </div>

    <div class="copyright">
      Copyright &copy; {{ year }}. All rights reserved.
    </div>
  </div>
</div>
