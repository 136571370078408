import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

import { IUser } from '@shared/models/user';
import { profileLinks } from '@core/constants';
import { ILink, IProfileLink } from '@shared/models/links';
import { FeatureFlagsService } from '@core/services/feature-flags.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthUser } from '@state/auth/auth.selectors';
import { setAuthModal } from '@state/auth/auth.actions';

@UntilDestroy()
@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {
  @Input() open = false;
  @Input() links: ILink[] = [];
  @Output() closeMenu = new EventEmitter<void>();

  protected user?: IUser;
  protected showSubmenu: number | null = null;
  protected year = new Date().getFullYear();

  protected featureFlags: { [key: string]: boolean } = {
    fe_favorite_property: false,
    fe_ui_docs: false,
    fe_buy_boxes: false,
  };

  protected currentPath?: string;
  protected linksWithActiveStatus: (IProfileLink & { isActive: boolean })[] =
    [];
  protected profileLinks: IProfileLink[] = profileLinks;

  constructor(
    private store: Store<AppState>,
    private featureFlagsService: FeatureFlagsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.user = user));

    this.currentPath = this.router.url;
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      this.currentPath = this.router.url;
      this.updateLinksActiveState();
    });

    this.updateLinksActiveState();

    this.featureFlagsService
      .isEnabled('fe_favorite_property')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_favorite_property'] = !!enabled;
      });
    this.featureFlagsService
      .isEnabled('fe_ui_docs')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_ui_docs'] = !!enabled;
      });
    this.featureFlagsService
      .isEnabled('fe_buy_boxes')
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        this.featureFlags['fe_buy_boxes'] = !!enabled;
      });
  }

  trackByFn(index: number, item: ILink): string {
    return item.title;
  }

  toggleSubmenu(e: Event, index: number): void {
    if (this.links[index].children) {
      e.preventDefault();
      this.showSubmenu = this.showSubmenu === index ? null : index;
    }
  }

  closeMenuHandler(): void {
    this.closeMenu.emit();
  }

  showSignInModal(): void {
    this.closeMenuHandler();
    this.store.dispatch(setAuthModal({ authModal: 'signin' }));
  }

  private updateLinksActiveState(): void {
    this.linksWithActiveStatus = this.profileLinks.map((item) => ({
      ...item,
      isActive: !!item.children?.some(
        (child) => child.path === this.currentPath
      ),
    }));
  }
}
