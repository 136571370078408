import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
  LIST_OWN_EXTERNAL_PROPERTIES,
  MY_DEALS_METRICS,
} from '@graphql/queries/my-deals.queries';
import {
  IListOwnExternalPropertiesData,
  IMyDealsMetricsData,
} from '@shared/models/my-deals';

@Injectable({
  providedIn: 'root',
})
export class MyDealsService {
  constructor(private apollo: Apollo) {}

  listOwnExternalProperties(): Observable<IListOwnExternalPropertiesData> {
    return this.apollo
      .query({
        query: LIST_OWN_EXTERNAL_PROPERTIES,
      })
      .pipe(map((result) => result.data));
  }

  myDealsMetrics(): Observable<IMyDealsMetricsData> {
    return this.apollo
      .query({
        query: MY_DEALS_METRICS,
      })
      .pipe(map((result) => result.data));
  }
}
