import { createReducer, on } from '@ngrx/store';

import {
  IExternalPropertyUserListing,
  IMyDealsMetricsData,
} from '@shared/models/my-deals';

import {
  resetMyDealsAction,
  listOwnExternalPropertiesAction,
  listOwnExternalPropertiesActionSuccess,
  listOwnExternalPropertiesActionFailure,
  myDealsMetricsAction,
  myDealsMetricsActionSuccess,
  myDealsMetricsActionFailure,
} from './my-deals.actions';

export interface MyDealsState {
  properties: {
    loading: boolean;
    errors: boolean;
    data: IExternalPropertyUserListing[];
  };
  metrics: {
    loading: boolean;
    errors: boolean;
    data?: IMyDealsMetricsData['self'];
  };
}

export const initialState: MyDealsState = {
  properties: {
    loading: false,
    errors: false,
    data: [],
  },
  metrics: {
    loading: false,
    errors: false,
  },
};

export const myDealsReducer = createReducer(
  initialState,
  on(resetMyDealsAction, () => ({ ...initialState })),

  on(listOwnExternalPropertiesAction, (state) => ({
    ...state,
    properties: {
      ...state.properties,
      loading: true,
    },
  })),
  on(listOwnExternalPropertiesActionSuccess, (state, action) => ({
    ...state,
    properties: {
      loading: false,
      errors: false,
      data: action.properties,
    },
  })),
  on(listOwnExternalPropertiesActionFailure, (state) => ({
    ...state,
    properties: {
      ...state.properties,
      loading: false,
      errors: true,
    },
  })),

  on(myDealsMetricsAction, (state) => ({
    ...state,
    metrics: {
      ...state.metrics,
      loading: true,
    },
  })),
  on(myDealsMetricsActionSuccess, (state, action) => ({
    ...state,
    metrics: {
      loading: false,
      errors: false,
      data: action.metrics,
    },
  })),
  on(myDealsMetricsActionFailure, (state) => ({
    ...state,
    metrics: {
      ...state.metrics,
      loading: false,
      errors: true,
    },
  }))
);
