import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuth } from '@state/auth/auth.selectors';
import { setAuthModal } from '@state/auth/auth.actions';

import { ACCOUNT_SETUP_MIN_DATE } from '@core/constants/auth';

export type CanActivateRes =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CanActivateRes {
    return this.store.select(selectAuth).pipe(
      map((auth) => {
        if (!auth.token) {
          const magicToken = route.queryParams['magicToken'];

          if (magicToken) {
            const queryParams = { ...route.queryParams };
            delete queryParams['magicToken'];

            // Reconstruct the originalUrl without magicToken
            const urlWithoutMagicToken = this.router.createUrlTree(
              [state.url.split('?')[0]],
              { queryParams }
            );

            const cleanOriginalUrl =
              this.router.serializeUrl(urlWithoutMagicToken);

            return this.router.createUrlTree(['/auth/magic-token'], {
              queryParams: { token: magicToken, originalUrl: cleanOriginalUrl },
            });
          }

          return this.router.createUrlTree(['/auth/sign-in']);
        } else if (state.url === '/logout') {
          return true;
        } else if (!auth.user?.confirmedAt) {
          this.store.dispatch(setAuthModal({ authModal: 'verify-identity' }));
        } else if (
          (!auth.user?.preference || !auth.user.preference.roles) &&
          new Date(auth.user?.insertedAt) >= ACCOUNT_SETUP_MIN_DATE
        ) {
          this.store.dispatch(setAuthModal({ authModal: 'account-setup' }));
        } else if (auth?.user?.isActive === false) {
          return this.router.createUrlTree(['/auth/deactivated']);
        }
        return true;
      })
    );
  }
}
