import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { signUpAction } from '@state/auth/auth.actions';
import {
  selectAuthLoading,
  selectAuthErrors,
  selectReferrerCode,
} from '@state/auth/auth.selectors';

import {
  passwordValidator,
  compareValidator,
} from '@core/constants/validators';
import { StringPipe } from '@shared/pipes';
import { PHONE_NUMBER_REGEX } from '@core/constants/regex';
import { AuthService } from '@core/services/auth.service';
import { IUserInviteSource } from '@shared/models/auth';

@UntilDestroy()
@Component({
  selector: 'app-signup-modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class SignUpFormComponent implements OnInit {
  public loading = false;
  public focused = '';
  protected passwordVisibility = 'password';
  protected passwordConVisibility = 'password';
  protected loadingInviteSources = false;
  protected inviteSources: IUserInviteSource[] = [];

  public signUpForm: FormGroup = this.formBuilder.group({
    firstName: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      this.customEmailValidator(),
    ]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(PHONE_NUMBER_REGEX),
    ]),
    password: new FormControl('', passwordValidator),
    passwordConfirmation: new FormControl('', [Validators.required]),
    terms: new FormControl(false, [Validators.requiredTrue]),
    allowMarketingEmails: new FormControl(false),
    referrerCode: new FormControl(null),
    inviteSource: new FormControl('', [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private authService: AuthService
  ) {
    this.signUpForm
      .get('passwordConfirmation')
      ?.setValidators([
        ...passwordValidator,
        compareValidator(
          this.signUpForm.get('password'),
          this.signUpForm.get('passwordConfirmation')
        ),
      ]);
  }

  ngOnInit(): void {
    this.signUpForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.focused) {
        this.signUpForm.controls[this.focused].markAsTouched();
      }
    });

    this.signUpForm.controls['password'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.signUpForm.controls[
          'passwordConfirmation'
        ].updateValueAndValidity();
      });

    this.store
      .select(selectAuthLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
        if (loading) {
          this.signUpForm.disable();
        } else {
          this.signUpForm.enable();
        }
      });

    this.store
      .select(selectAuthErrors)
      .pipe(untilDestroyed(this))
      .subscribe((errors) => {
        if (errors) {
          this.signUpForm.markAllAsTouched();
          errors.forEach((error) => {
            if (error.fields[0] === 'referrer_code') return;
            if (
              error.fields[0] === 'phone_number' &&
              error.shortMessage.includes('%{regex}')
            ) {
              this.signUpForm.controls[
                new StringPipe().transform(error.fields[0], 'toCamelCase')
              ].setErrors({
                server: 'Phone number is invalid',
              });
            } else {
              this.signUpForm.controls[
                new StringPipe().transform(error.fields[0], 'toCamelCase')
              ].setErrors({
                server: error.shortMessage,
              });
            }
          });
        }
      });

    this.store
      .select(selectReferrerCode)
      .pipe(untilDestroyed(this))
      .subscribe((referrerCode) => {
        this.signUpForm.controls['referrerCode'].setValue(referrerCode || null);
      });

    this.authService
      .listUserInviteSources()
      .pipe(take(1))
      .subscribe((data) => {
        this.loadingInviteSources = false;
        this.inviteSources = data.listUserInviteSources;
      });
  }

  submitHandler() {
    if (this.signUpForm.invalid) {
      this.signUpForm.markAllAsTouched();
      return;
    }
    try {
      const values = this.signUpForm.value;
      delete values.terms;
      this.store.dispatch(
        signUpAction({
          input: { ...values, phoneNumber: '+1' + values.phoneNumber },
        })
      );
    } catch (err) {
      throw err;
    }
  }

  pastePhoneNumberHandler(event: ClipboardEvent) {
    const pastedText = event.clipboardData
      ?.getData('text/plain')
      .replace(/[\s()-]/g, '');
    setTimeout(() => {
      if (pastedText?.startsWith('+1')) {
        this.signUpForm.controls['phoneNumber'].setValue(
          pastedText?.substring(2, pastedText.length)
        );
      }
    }, 0);
  }

  focusHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    this.focused = name;
  }

  blurHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    if (this.focused === name) this.focused = '';
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
      const valid = emailRegex.test(control.value);
      return valid ? null : { email: { value: control.value } };
    };
  }
}
