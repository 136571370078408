import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
  ALL_INVESTORS,
  ALL_INVESTORS_BY_PHONE_NUMBER,
} from '@graphql/queries/investors.queries';
import {
  IAllInvestorsInput,
  IAllInvestorsData,
  IAllInvestorsByPhoneNumberInput,
  IAllInvestorsByPhoneNumberData,
} from '@shared/models/investors';

@Injectable({
  providedIn: 'root',
})
export class InvestorsService {
  constructor(private apollo: Apollo) {}

  allInvestors(input: IAllInvestorsInput): Observable<IAllInvestorsData> {
    return this.apollo
      .query({
        query: ALL_INVESTORS,
        variables: input,
      })
      .pipe(map((result) => result.data));
  }

  allInvestorsByPhoneNumber(
    input: IAllInvestorsByPhoneNumberInput
  ): Observable<IAllInvestorsByPhoneNumberData> {
    return this.apollo
      .query({
        query: ALL_INVESTORS_BY_PHONE_NUMBER,
        variables: input,
      })
      .pipe(map((result) => result.data));
  }
}
