import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IListOwnExternalPropertiesData,
  IMyDealsMetricsData,
} from '@shared/models/my-deals';

export const LIST_OWN_EXTERNAL_PROPERTIES: TypedDocumentNode<IListOwnExternalPropertiesData> = gql`
  query listOwnExternalProperties {
    listOwnExternalProperties {
      id
      hasMatch
      normalizedAddress
      insertedAt
      arv
      renovationCostEstimate
      price
      status
      userStatus
    }
  }
`;

export const MY_DEALS_METRICS: TypedDocumentNode<IMyDealsMetricsData> = gql`
  query self {
    self {
      id
      totalExternalProperties
      totalMatchedExternalProperties
      totalNotMatchedExternalProperties
      totalPipelineExternalProperties
    }
  }
`;
