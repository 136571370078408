import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';

import { MagicTokenService } from '@core/services/magic-token.service';
import { environment } from 'src/environments/environment';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthToken } from '@state/auth/auth.selectors';

const url = `${environment.api}/deal/submission`;

export const openSubmitDeal = (magicToken?: string) => {
  const finalUrl = magicToken ? `${url}?magic_token=${magicToken}` : url;
  window.location.href = finalUrl;
};

@UntilDestroy()
@Component({
  selector: 'app-submit-a-deal-button',
  templateUrl: './submit-a-deal.component.html',
  styleUrls: ['./submit-a-deal.component.scss'],
})
export class SubmitADealComponent implements OnInit {
  @Input() wrapper: TemplateRef<any> | null = null;

  protected text = 'Submit a Deal';
  protected loading = false;
  protected isLoggedIn = false;

  constructor(
    private store: Store<AppState>,
    private magicTokenService: MagicTokenService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthToken)
      .pipe(untilDestroyed(this))
      .subscribe((token) => {
        this.isLoggedIn = !!token;
      });
  }

  handleClick(): void {
    if (this.loading) return;

    if (!this.isLoggedIn) {
      openSubmitDeal();
    } else {
      this.loading = true;
      this.text = 'Redirecting...';

      this.magicTokenService
        .requestMagicToken()
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.loading = false;
            this.text = 'Submit a Deal';
          })
        )
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.text = 'Submit a Deal';
            this.cdr.detectChanges();
            setTimeout(() => {
              openSubmitDeal(res.requestMagicToken);
            }, 100);
          },
        });
    }
  }
}
