import { ILink, IProfileLink, ISocialLink } from '@shared/models/links';

// export const menuLinks: ILink[] = [
//   { title: 'Buy', path: 'https://www.rebuilt.com/buy' },
//   { title: 'Sell', path: 'https://www.rebuilt.com/sell' },
//   {
//     title: 'Locations',
//     path: null,
//     children: [
//       {
//         title: 'Nashville',
//         path: 'https://www.rebuilt.com/our-locations/nashville',
//       },
//       {
//         title: 'Lexington',
//         path: 'https://www.rebuilt.com/our-locations/lexington',
//       },
//       {
//         title: 'Knoxville',
//         path: 'https://www.rebuilt.com/our-locations/knoxville',
//       },
//       {
//         title: 'Chattanooga',
//         path: 'https://www.rebuilt.com/our-locations/chattanooga',
//       },
//       {
//         title: 'Philadelphia',
//         path: 'https://www.rebuilt.com/our-locations/philadelphia',
//       },
//       {
//         title: 'Austin',
//         path: 'https://www.rebuilt.com/our-locations/austin',
//       },
//       {
//         title: 'San Antonio',
//         path: 'https://www.rebuilt.com/our-locations/san-antonio',
//       },
//       {
//         title: 'Hunstville',
//         path: 'https://www.rebuilt.com/our-locations/hunstville',
//       },
//       {
//         title: 'Louisville',
//         path: 'https://www.rebuilt.com/our-locations/louisville',
//       },
//     ],
//   },
//   {
//     title: 'Resources',
//     path: null,
//     children: [
//       { title: 'Blog', path: 'https://www.rebuilt.com/blog' },
//       { title: 'FAQ', path: 'https://www.rebuilt.com/faq' },
//       { title: 'Guides', path: 'https://www.rebuilt.com/guide' },
//       { title: 'Opt Out', path: 'https://www.rebuilt.com/optout' },
//     ],
//   },
//   { title: 'Lending', path: 'https://www.rebuilt.com/lending' },
// ];

export const menuLinks: ILink[] = [
  // { title: 'Submit a Deal', path: 'https://www.rebuilt.com/partnerships' },
  { title: 'Order Title', path: 'https://www.national-title.com/general-4' },
  { title: 'Apply for Loan', path: 'https://www.rebuilt.com/lending' },
  {
    title: 'Get Insurance Quote',
    path: 'https://rebuilt.steadilypartner.com/',
  },
  {
    title: 'Careers',
    path: 'https://lifeatrebuilt.com',
  },
  // {
  //   title: 'Buy Mortgage Notes',
  //   path: 'https://securedinvestmentcorp.com/rebuilt  ',
  // },
  // {
  //   title: 'Marketplace Alerts',
  //   path: 'https://share.hsforms.com/1YtMANFwCRwOcVbj3L21GZAddzar',
  // },
];

export const socialLinks: ISocialLink[] = [
  {
    title: 'Facebook',
    icon: 'social-facebook-white',
    path: 'https://www.facebook.com/rebuiltoffers/',
  },
  {
    title: 'Instagram',
    icon: 'social-instagram-white',
    path: 'https://www.instagram.com/rebuiltoffers/',
  },
  // {
  //   title: 'Twitter',
  //   icon: 'social-twitter-white',
  //   path: 'https://twitter.com/rebuiltrealty',
  // },
  {
    title: 'LinkedIn',
    icon: 'social-linkedin-white',
    path: 'https://www.linkedin.com/company/rebuilt-investments/',
  },
];

export const profileLinks: IProfileLink[] = [
  {
    title: 'Buy',
    path: null,
    icon: 'featherHome',
    children: [
      { title: 'Marketplace', path: '/page/1' },
      { title: 'Offers', path: '/profile/offers' },
      {
        title: 'Favorites',
        path: '/profile/favorites',
        featureFlag: 'fe_favorite_property',
      },
      {
        title: 'Buy Boxes',
        path: '/buy-boxes',
        featureFlag: 'fe_buy_boxes',
      },
    ],
  },
  {
    title: 'Sell',
    path: null,
    icon: 'featherDollarSign',
    children: [
      {
        title: 'My Deals',
        path: '/my-deals',
      },
    ],
  },
  {
    title: 'Account',
    path: null,
    icon: 'featherUser',
    children: [
      {
        title: 'Profile',
        path: '/profile/account',
      },
      {
        title: 'Logout',
        path: '/logout',
      },
    ],
  },
  {
    title: 'UI Docs',
    path: null,
    icon: 'featherFileText',
    featureFlag: 'fe_ui_docs',
    removeFromProduction: true,
    children: [
      {
        title: 'Button',
        path: '/ui-docs/button',
      },
      {
        title: 'Colors',
        path: '/ui-docs/colors',
      },
      {
        title: 'Multi Selector',
        path: '/ui-docs/multi-selector',
      },
    ],
  },
];
