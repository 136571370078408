import { createAction, props } from '@ngrx/store';

import { IPageOfPropertyInput, IProperty } from '@shared/models/properties';
import { IPageOfFavorites } from '@shared/models/favorites';

export const resetFavoritesAction = createAction('[Favorites] Reset');

export const fetchFavoritesAction = createAction(
  '[Favorites] Fetch Favorites',
  props<IPageOfPropertyInput>()
);

export const fetchFavoritesActionSuccess = createAction(
  '[Favorites] Fetch Favorites Success',
  props<IPageOfFavorites>()
);

export const fetchFavoritesActionFailure = createAction(
  '[Favorites] Fetch Favorites Failure'
);

export const setPropertyAsFavoriteAction = createAction(
  '[Favorites] Set Property As Favorite',
  props<{ propertyId: string }>()
);

export const setPropertyAsFavoriteActionSuccess = createAction(
  '[Favorites] Set Property As Favorite Success',
  props<{ property: IProperty }>()
);

export const setPropertyAsFavoriteActionFinish = createAction(
  '[Favorites] Set Property As Favorite Finish'
);

export const removePropertyFromFavoritesAction = createAction(
  '[Favorites] Remove Property From Favorites',
  props<{ propertyId: string }>()
);

export const removePropertyFromFavoritesActionSuccess = createAction(
  '[Favorites] Remove Property From Favorites Success',
  props<{ property: IProperty }>()
);

export const removePropertyFromFavoritesActionFinish = createAction(
  '[Favorites] Remove Property From Favorites Finish'
);
