import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { InvestorsService } from '@core/services/investors.service';
import {
  IAllInvestorsData,
  IAllInvestorsInput,
} from '@shared/models/investors';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthUser } from '@state/auth/auth.selectors';

const currentYear = new Date().getFullYear();

@UntilDestroy()
@Component({
  selector: 'app-account-setup-modal-form-investor2',
  templateUrl: './investor2.component.html',
  styleUrls: ['./investor2.component.scss'],
})
export class AccountSetupInvestor2Component implements OnInit {
  @Input({ required: true }) error = false;

  protected searchTerm: string = '';
  protected searchTermSubject = new Subject<string>();
  protected options: { key: string; value: string; desc?: string }[] = [];

  protected loadingByPhoneNumber = true;
  protected investorsByPhoneNumber: { id: string; fullName: string }[] = [];

  protected isMobile = false;
  protected loading = false;
  protected statusMessage: string = '';
  private messageTimeouts: NodeJS.Timeout[] = []; // To track and clear timeouts

  public hasExactMatch: boolean = false;
  protected selected: { key: string; value: string }[] = [];
  protected investors$: Observable<IAllInvestorsData> = of({
    allInvestors: [],
  });

  @Output() selectedChange = new EventEmitter<
    { key: string; value: string }[]
  >();
  @Output() nextStep = new EventEmitter<void>();

  constructor(
    private investorsService: InvestorsService,
    private deviceService: DeviceDetectorService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    this.store
      .select(selectAuthUser)
      .pipe(take(1))
      .subscribe((user) => {
        if (!user?.phoneNumber) {
          this.loadingByPhoneNumber = false;
        } else {
          this.investorsService
            .allInvestorsByPhoneNumber({
              phoneNumber: user.phoneNumber,
              onlyValid: true,
            })
            .pipe(take(1))
            .subscribe((data) => {
              if (data.allInvestorsByPhoneNumber.length >= 1) {
                this.selected = data.allInvestorsByPhoneNumber.map(
                  (investor) => ({
                    key: investor.id,
                    value: investor.fullName,
                  })
                );
                this.selectedChange.emit(this.selected);
                this.nextStep.emit();
              }
              this.investorsByPhoneNumber = data.allInvestorsByPhoneNumber;
              this.loadingByPhoneNumber = false;
            });
        }
      });

    this.investors$ = this.searchTermSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term.length >= 3) {
          return this.fetchInvestors(term).pipe(
            tap((data) => {
              this.options = data.allInvestors.map((investor) => ({
                key: investor.id,
                value: investor.fullName,
                desc:
                  `${currentYear} Purchases: ` +
                  (investor.currentYearBuyRecords || 0) +
                  ` &nbsp;|&nbsp; ${currentYear - 1} Purchases: ` +
                  (investor.lastYearBuyRecords || 0),
              }));
              this.loading = false;
              this.updateExactMatch();
            }),
            catchError((error) => {
              this.loading = false;
              return of({ allInvestors: [] });
            })
          );
        } else {
          // No need to set loading = false here; it's handled in handleSearchChange
          return of({ allInvestors: [] });
        }
      })
    );
    // Subscribe to activate the observable chain
    this.investors$.pipe(untilDestroyed(this)).subscribe();
  }

  fetchInvestors(term: string): Observable<IAllInvestorsData> {
    const input: IAllInvestorsInput = { fullName: term };
    return this.investorsService.allInvestors(input);
  }

  onSearchChange(): void {
    const trimmedValue = this.searchTerm.trim();
    if (trimmedValue.length >= 3) {
      this.loading = true;
      this.options = [];
      this.setStatusMessages();
    } else {
      this.clearStatusMessages();
      this.loading = false;
      this.options = [];
    }
    this.searchTermSubject.next(trimmedValue);
  }

  setStatusMessages(): void {
    this.clearStatusMessages(); // Clear any existing timeouts
    this.statusMessage = 'Finding matching entities...';

    // Schedule status message updates
    this.messageTimeouts.push(
      setTimeout(() => (this.statusMessage = 'Getting the details...'), 3000)
    );
    this.messageTimeouts.push(
      setTimeout(() => (this.statusMessage = 'Processing results...'), 7000)
    );
    this.messageTimeouts.push(
      setTimeout(() => (this.statusMessage = 'Just a moment...'), 11000)
    );
  }

  clearStatusMessages(): void {
    // Clear all timeouts to prevent overlapping messages
    this.messageTimeouts.forEach(clearTimeout);
    this.messageTimeouts = [];
    this.statusMessage = ''; // Reset status message
  }

  trackByOptionKey(
    index: number,
    option: { key: string; value: string }
  ): string {
    return option.key;
  }

  toggleSelected(option: { key: string; value: string }): void {
    const exists = this.selected.some(
      (selected) => selected.key === option.key
    );
    if (exists) {
      this.selected = this.selected.filter(
        (selected) => selected.key !== option.key
      );
    } else {
      this.selected = [option, ...this.selected];
    }
    this.selectedChange.emit(this.selected);
  }

  removeSelected(key: string): void {
    this.selected = this.selected.filter((selected) => selected.key !== key);
    this.selectedChange.emit(this.selected);
  }

  isSelected(key: string): boolean {
    return this.selected.some((selectedOption) => selectedOption.key === key);
  }

  isSelectedCustom(value: string): boolean {
    return this.selected.some(
      (selectedOption) =>
        selectedOption.key === '-' && selectedOption.value === value
    );
  }

  updateExactMatch(): void {
    this.hasExactMatch = this.options.some(
      (option) =>
        option.value.toLowerCase().trim() ===
        this.searchTerm.toLowerCase().trim()
    );
  }

  addCustomValue(): void {
    const exists = this.selected.some(
      (option) => option.key === '-' && option.value === this.searchTerm
    );
    if (!exists) {
      this.selected = [{ key: '-', value: this.searchTerm }, ...this.selected];
      this.selectedChange.emit(this.selected);
    }
  }
}
