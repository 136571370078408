import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IAllInvestorsInput,
  IAllInvestorsData,
  IAllInvestorsByPhoneNumberInput,
  IAllInvestorsByPhoneNumberData,
} from '@shared/models/investors';

export const ALL_INVESTORS: TypedDocumentNode<
  IAllInvestorsData,
  IAllInvestorsInput
> = gql`
  query allInvestors($fullName: String!) {
    allInvestors(fullName: $fullName) {
      id
      fullName
      currentYearBuyRecords
      lastYearBuyRecords
    }
  }
`;

export const ALL_INVESTORS_BY_PHONE_NUMBER: TypedDocumentNode<
  IAllInvestorsByPhoneNumberData,
  IAllInvestorsByPhoneNumberInput
> = gql`
  query allInvestorsByPhoneNumber($phoneNumber: String!, $onlyValid: Boolean) {
    allInvestorsByPhoneNumber(
      phoneNumber: $phoneNumber
      onlyValid: $onlyValid
    ) {
      id
      fullName
    }
  }
`;
