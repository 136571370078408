import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { validateGraphQLErrors } from '@core/utils/errors';
import { AuthService } from '@core/services/auth.service';
import { ProfileService } from '@core/services/profile.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectLastSMSSent } from '@state/auth/auth.selectors';
import { resetConfigAction } from '@state/config/config.actions';
import { resetPropertiesAction } from '@state/properties/properties.actions';
import { resetOffersAction } from '@state/offers/offers.actions';
import { resetMyDealsAction } from '@state/my-deals/my-deals.actions';
import { resetBuyBoxesAction } from '@state/buy-boxes/buy-boxes.actions';
import { resetFavoritesAction } from '@state/favorites/favorites.actions';
// import { resetFiltersAction } from '@state/filters/filters.actions';

import {
  signInAction,
  signInActionSuccess,
  signInActionFailure,
  signInWithMagicLinkAction,
  signInWithMagicLinkActionSuccess,
  signInWithMagicLinkActionFailure,
  signUpAction,
  signUpActionSuccess,
  signUpActionFailure,
  signOutAction,
  fetchUserAction,
  fetchUserActionSuccess,
  setupAccountAction,
  setupAccountActionSuccess,
  setupAccountActionFailure,
  updateUserPreferenceAction,
  updateUserPreferenceActionSuccess,
  updateUserPreferenceActionFailure,
  requestPasswordResetAction,
  requestPasswordResetActionFinish,
  resetPasswordAction,
  resetPasswordActionFinish,
  sendPhoneNumberConfirmationAction,
  confirmPhoneNumberAction,
  setLoadingAction,
  setLastSMSSentAction,
  updatePhoneNumberAction,
  updatePhoneNumberSuccessAction,
  setReferrerCodeAction,
  setEntityIdAction,
  updateProfileAction,
  updateProfileActionSuccess,
  updateProfileActionFailure,
  updatePasswordAction,
  updatePasswordActionSuccess,
  updatePasswordActionFailure,
  setAuthModal,
  fetchUserEntitiesAction,
  fetchUserEntitiesActionSuccess,
  fetchUserEntitiesActionFailure,
} from './auth.actions';

import { DEFAULT_FEATURE_FLAGS } from '@core/constants/feature-flags';
import { fetchFeatureFlagsAction } from '../config/config.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {}

  signInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInAction),
      switchMap((action) =>
        this.authService.signInUser(action).pipe(
          map((res) => {
            if (res.signInWithPassword.token) {
              return signInActionSuccess(res.signInWithPassword);
            }
            throw Error('The email or password is incorrect');
          }),
          catchError(() => {
            this.toastr.error('The email or password is incorrect');
            return [signInActionFailure({ errors: [] })];
          })
        )
      )
    )
  );

  signInUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signInActionSuccess),
        tap((userWithToken) => {
          this.toastr.clear();
          this.toastr.success('You have logged in');
          this.store.dispatch(
            fetchFeatureFlagsAction({ keys: [...DEFAULT_FEATURE_FLAGS] })
          );
          this.store.dispatch(setReferrerCodeAction({ referrerCode: null }));
          this.store.dispatch(setAuthModal({ authModal: undefined }));
          if (!!userWithToken.preference) {
            this.store.dispatch(setEntityIdAction({ entityId: null }));
          }
        })
      ),
    { dispatch: false }
  );

  signInWithMagicLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInWithMagicLinkAction),
      switchMap((action) =>
        this.authService.signInWithMagicLink(action.input).pipe(
          map((res) => {
            if (res.signInWithMagicLink.token) {
              this.router.navigate([action.url]);
              return signInWithMagicLinkActionSuccess(res.signInWithMagicLink);
            }
            throw Error('The magic link is incorrect');
          }),
          catchError(() => {
            this.toastr.error('Unable to sign in with magic link');
            this.router.navigate(['/']);
            return [signInWithMagicLinkActionFailure({ errors: [] })];
          })
        )
      )
    )
  );

  signInWithMagicLinkSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signInWithMagicLinkActionSuccess),
        tap((userWithToken) => {
          this.toastr.clear();
          this.toastr.success('You have logged in');
          this.store.dispatch(
            fetchFeatureFlagsAction({ keys: [...DEFAULT_FEATURE_FLAGS] })
          );
          this.store.dispatch(setReferrerCodeAction({ referrerCode: null }));
          this.store.dispatch(setAuthModal({ authModal: undefined }));
          if (!!userWithToken.preference) {
            this.store.dispatch(setEntityIdAction({ entityId: null }));
          }
        })
      ),
    { dispatch: false }
  );

  signUpUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpAction),
      switchMap((action) =>
        this.authService.signUpUser(action).pipe(
          map((res) => {
            if (res.registerWithPassword.metadata?.token) {
              return signUpActionSuccess(res.registerWithPassword);
            } else {
              throw res.registerWithPassword.errors;
            }
          }),
          catchError((error) => {
            this.toastr.error('Errors occurred during registration');
            if (validateGraphQLErrors(error)) {
              return [signUpActionFailure({ errors: error })];
            }
            return [signUpActionFailure({ errors: [] })];
          })
        )
      )
    )
  );

  signUpUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signUpActionSuccess),
        tap(() => {
          if (window.dataLayer) {
            window.dataLayer.push({ event: 'registrationComplete' });
          }
          this.toastr.clear();
          this.toastr.success('You have registered');
          this.store.dispatch(
            fetchFeatureFlagsAction({ keys: [...DEFAULT_FEATURE_FLAGS] })
          );
          this.store.dispatch(setReferrerCodeAction({ referrerCode: null }));
          this.router.navigate(['/', 'home']);
        })
      ),
    { dispatch: false }
  );

  signOutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signOutAction),
        tap(() => {
          this.store.dispatch(resetConfigAction());
          this.store.dispatch(resetPropertiesAction());
          this.store.dispatch(resetOffersAction());
          // this.store.dispatch(resetFiltersAction());
          this.store.dispatch(resetFavoritesAction());
          this.store.dispatch(resetBuyBoxesAction());
          this.store.dispatch(resetMyDealsAction());
          this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  setupAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setupAccountAction),
      switchMap((action) => {
        return this.authService.createUserPreference(action).pipe(
          map((res) => {
            if (res.createUserPreference.result) {
              this.store.dispatch(setEntityIdAction({ entityId: null }));
              return setupAccountActionSuccess(res.createUserPreference.result);
            } else {
              throw res.createUserPreference.errors;
            }
          }),
          catchError((error) => {
            this.toastr.error('Errors occurred during account setup');
            return [setupAccountActionFailure({ errors: error })];
          })
        );
      })
    )
  );

  setupAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setupAccountActionSuccess),
        tap(() => {
          this.store.dispatch(setAuthModal({ authModal: 'welcome-user' }));
        })
      ),
    { dispatch: false }
  );

  updateUserPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserPreferenceAction),
      switchMap((action) =>
        this.authService.updateUserPreference(action.id, action.input).pipe(
          map((res) => {
            if (res.updateUserPreference.result) {
              this.store.dispatch(fetchUserAction());
              return updateUserPreferenceActionSuccess(
                res.updateUserPreference.result
              );
            } else {
              throw res.updateUserPreference.errors;
            }
          }),
          catchError((error) => {
            this.toastr.error(
              'Errors occurred during updating user preference'
            );
            return [updateUserPreferenceActionFailure({ errors: error })];
          })
        )
      )
    )
  );

  updateUserPreferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserPreferenceActionSuccess),
        tap(() => {
          this.toastr.success('User preference has been updated');
        })
      ),
    { dispatch: false }
  );

  fetchUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserAction),
      switchMap(() =>
        this.authService.fetchUser().pipe(
          map((res) => {
            if (res.self) {
              return fetchUserActionSuccess(res.self);
            } else {
              throw Error('Errors occurred during fetching user');
            }
          }),
          catchError(() => {
            return [];
          })
        )
      )
    )
  );

  fetchUserEntitiesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserEntitiesAction),
      switchMap((action) =>
        this.authService.fetchUserEntities(action.userId).pipe(
          map((res) => {
            if (res.listByUser) {
              return fetchUserEntitiesActionSuccess(res);
            } else {
              throw Error('Errors occurred during fetching user entities');
            }
          }),
          catchError(() => {
            return [fetchUserEntitiesActionFailure()];
          })
        )
      )
    )
  );

  requestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestPasswordResetAction),
      switchMap((action) =>
        this.authService.requestPasswordReset(action.email).pipe(
          map((res) => {
            if (res.requestPasswordReset && res.requestPasswordReset === 'OK') {
              this.toastr.success('Password reset link has been sent');
              return requestPasswordResetActionFinish({ success: true });
            } else {
              throw Error('Errors occurred during password reset');
            }
          }),
          catchError((error) => {
            this.toastr.error('Errors occurred during password reset');
            return [requestPasswordResetActionFinish({ success: false })];
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPasswordAction),
      switchMap((action) =>
        this.authService.passwordResetWithPassword(action).pipe(
          map((res) => {
            if (res.passwordResetWithPassword.metadata.token) {
              this.toastr.success('Password has been reset');
              this.router.navigate(['/']);
              setTimeout(() => {
                this.store.dispatch(setAuthModal({ authModal: 'signin' }));
              }, 0);
              return resetPasswordActionFinish();
            } else {
              throw Error('Errors occurred during password reset');
            }
          }),
          catchError(() => {
            this.toastr.error('Errors occurred during password reset');
            return [resetPasswordActionFinish()];
          })
        )
      )
    )
  );

  sendPhoneNumberConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPhoneNumberConfirmationAction),
      switchMap(() => {
        let lastSMSSentStore: string | undefined;
        const subscription = this.store
          .select(selectLastSMSSent)
          .subscribe((lastSMSSent) => {
            lastSMSSentStore = lastSMSSent;
          });

        if (lastSMSSentStore) {
          const lastSMSSent = new Date(lastSMSSentStore);
          const now = new Date();
          const diff = Math.abs(now.getTime() - lastSMSSent.getTime());
          const diffSeconds = Math.ceil(diff / 1000);
          if (diffSeconds < 30) {
            this.toastr.clear();
            this.toastr.error(
              'You can ask for confirmation code only once in 30 seconds.' +
                ' Please try again in ' +
                (30 - diffSeconds) +
                ' seconds'
            );
            return [setLoadingAction({ loading: false })];
          }
        }

        this.store.dispatch(setLoadingAction({ loading: true }));
        this.store.dispatch(
          setLastSMSSentAction({ lastSMSSent: new Date().toString() })
        );

        return this.authService.sendPhoneNumberConfirmation().pipe(
          map((res) => {
            const { sendPhoneNumberConfirmation } = res;
            if (sendPhoneNumberConfirmation === 'OK') {
              this.toastr.success('Confirmation code has been sent');
              subscription.unsubscribe();
              return setLoadingAction({ loading: false });
            } else {
              throw Error;
            }
          }),
          catchError(() => {
            this.toastr.error(
              'Errors occurred during confirmation code sending'
            );
            return [setLoadingAction({ loading: false })];
          })
        );
      })
    )
  );

  confirmPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmPhoneNumberAction),
      switchMap((action) =>
        this.authService.confirmPhoneNumber(action).pipe(
          map((res) => {
            const { confirmPhoneNumber } = res;
            if (
              confirmPhoneNumber.result &&
              !confirmPhoneNumber.errors?.length
            ) {
              // GA event
              if (window.dataLayer) {
                window.dataLayer.push({ event: 'confirmationComplete' });
              }
              this.toastr.success('Your phone number has been confirmed');
              this.store.dispatch(setAuthModal({ authModal: undefined }));
              return fetchUserActionSuccess(confirmPhoneNumber.result);
            } else {
              throw Error;
            }
          }),
          catchError(() => {
            this.toastr.error(
              'We could not confirm your phone number. ' +
                'Make sure you entered the correct code.'
            );
            return [setLoadingAction({ loading: false })];
          })
        )
      )
    )
  );

  updatePhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePhoneNumberAction),
      switchMap((action) =>
        this.authService.updatePhoneNumber(action).pipe(
          map((res) => {
            const { updatePhoneNumber } = res;
            if (updatePhoneNumber.result && !updatePhoneNumber.errors?.length) {
              this.toastr.success('Your phone number has been updated');
              return updatePhoneNumberSuccessAction({
                success: true,
                user: updatePhoneNumber.result,
              });
            } else {
              throw updatePhoneNumber.errors;
            }
          }),
          catchError((err) => {
            this.toastr.error('Errors occurred during phone number update');
            return [setLoadingAction({ loading: false })];
          })
        )
      )
    )
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfileAction),
      switchMap((action) =>
        this.profileService.updateProfile(action).pipe(
          map((res) => {
            const { updateProfile } = res;
            if (updateProfile.result && !updateProfile.errors?.length) {
              this.toastr.success('Your profile has been updated');
              return updateProfileActionSuccess({ ...res });
            } else {
              throw updateProfile.errors;
            }
          }),
          catchError((err) => {
            console.log(err);
            this.toastr.error('Errors occurred during profile update');
            return [updateProfileActionFailure(err)];
          })
        )
      )
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePasswordAction),
      switchMap((action) =>
        this.profileService.updatePassword(action).pipe(
          map((res) => {
            const { updatePassword } = res;
            if (updatePassword.result && !updatePassword?.errors.length) {
              this.toastr.success('Your password has been updated');
              return updatePasswordActionSuccess();
            } else {
              throw updatePassword.errors;
            }
          }),
          catchError((err) => {
            console.log(err);
            this.toastr.error(
              'We could not update your password, ' +
                'please make sure you entered the correct current password'
            );
            return [updatePasswordActionFailure(err)];
          })
        )
      )
    )
  );
}
