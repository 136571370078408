import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
  IListOwnExternalPropertiesData,
  IMyDealsMetricsData,
} from '@shared/models/my-deals';
import { MyDealsService } from '@core/services/my-deals.service';

import {
  listOwnExternalPropertiesAction,
  listOwnExternalPropertiesActionSuccess,
  listOwnExternalPropertiesActionFailure,
  myDealsMetricsAction,
  myDealsMetricsActionSuccess,
  myDealsMetricsActionFailure,
} from './my-deals.actions';

@Injectable()
export class MyDealsEffects {
  constructor(
    private actions$: Actions,
    private myDealsService: MyDealsService,
    private toastr: ToastrService
  ) {}

  listOwnExternalProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listOwnExternalPropertiesAction),
      switchMap(() =>
        this.myDealsService.listOwnExternalProperties().pipe(
          map((data: IListOwnExternalPropertiesData) => {
            return listOwnExternalPropertiesActionSuccess({
              properties: data.listOwnExternalProperties,
            });
          }),
          catchError(() => {
            this.toastr.error('Error fetching My Deals properties.');
            return [listOwnExternalPropertiesActionFailure()];
          })
        )
      )
    )
  );

  myDealsMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myDealsMetricsAction),
      switchMap(() =>
        this.myDealsService.myDealsMetrics().pipe(
          map((data: IMyDealsMetricsData) => {
            return myDealsMetricsActionSuccess({
              metrics: data.self,
            });
          }),
          catchError(() => {
            this.toastr.error('Error fetching My Deals metrics.');
            return [myDealsMetricsActionFailure()];
          })
        )
      )
    )
  );
}
